/**
*  Jquery Image Uploader 0.2
*  Copyright 2012, Kenneth Spencer
*/

import React, {useState, useCallback} from "react";
import {createRoot} from "react-dom/client";
import SB, {SbToken}  from "SB/js/SB";
import Progress from "./Progress";
import Browse from "./Browse";
import Remove from "./Remove";
import Avatar from "SB/components/avatar";

const blockEvent = evt => {
  evt.stopPropagation();
  evt.preventDefault();
}

const ImageUploader = (props) => {
  const {
    uploadPath = null, 
    deletePath = null, 
    fieldName = "avatar", 
    onupload = null,
    ondelete = null,
  } = props;

  const [avatar, setAvatar] = useState(props.avatar || null);
  const [uploading, setUploading] = useState(false);
  const [progress, setProgress] = useState({});

  const handleUpload = useCallback(file => {

    const types = ["image/jpeg", "image/png", "image/gif"];
    if (!types.includes(file.type)) {
      return
    }

    var formData = new FormData();
    formData.append(fieldName, file);

    /*  Upload the file to the server
     */
    var token = new SbToken();
    token.upload.on("progress", ({loaded, total}) => {
      setProgress({loaded, total});
    });
    
    
    setUploading(true);
    SB.post(uploadPath, formData, token)
    .then((data) => {
      if (data.avatar) {
        setAvatar(data.avatar);
        if (onupload) {
          onupload(data);
        }
      }
    }, e => {
      alert(e.message);
    })
    .finally(() => {
      setUploading(false);
    });  
  }, [fieldName, uploadPath, onupload]);

  const handleDrop = useCallback(evt => {
    evt.stopPropagation();
    evt.preventDefault();

    var dt = evt.dataTransfer;
    var files = dt.files;
    if (files.length === 0) {
      return;
    }
    handleUpload(files[0]);
  }, [handleUpload]);

  var image = null;
  if (avatar) {
    image = <Avatar {...avatar} />
  }
  
  return (
    <div 
      className={"image-uploader image-uploader-" + (uploading ? "uploading" : "ready")}
      onDragEnter={blockEvent}
      onDragOver={blockEvent}
      onDrop={handleDrop}
    >
      <div className="image-uploader-preview">
        {image}
      </div>
      {uploading || (
        <Remove 
          avatar={avatar} 
          setAvatar={setAvatar}
          deletePath={deletePath}
          ondelete={ondelete}
        />
      )}
      {uploading && <Progress {...progress} />}
      {uploading || <Browse handleUpload={handleUpload} />}
    </div>
  );
}

export const renderImageUploader = (node, props = {}) => {
  return createRoot(node).render(<ImageUploader {...props}/>);
}

global.renderImageUploader = renderImageUploader;
export default ImageUploader;
