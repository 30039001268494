import React, {useCallback} from "react";
import { useTranslation } from 'react-i18next';

const Browse = ({handleUpload}) => {
  const { t } = useTranslation();

  const handleChange  = useCallback(evt => {
    const input = evt.currentTarget;
    if (!input.files.length) {
      return;
    }

    const file = input.files[0];

    if (file.type === "image/jpeg" || file.type === "image/png" || file.type === "image/gif") {
      handleUpload(file);
    }
  }, [handleUpload]); 

  return (
    <div 
      className="image-uploader-browse"
      style={{overflow: "hidden"}}
    >
      <label>{t('button.change')}
        <input 
          type="file" 
          accept="image/jpeg,image/gif,image/png"
          onChange={handleChange}
        />
      </label>
    </div>

  );
}

export default Browse;
