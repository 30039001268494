import i18next from 'i18next'
import resourcesToBackend from 'i18next-resources-to-backend'
import { initReactI18next } from 'react-i18next'
import ICU from 'i18next-icu'

/**
 * @param {string} language
 * @param {string} namespace - format: "common" or <core|amira|depot|smallbox>/<bundle>/<filename (optional)>
 */

// function getResourceImport(language, namespace) {
//     // const namespaces = namespace.split('/')
//     const [workspace, bundle, filename] = namespace.split('/')

//     switch (workspace) {
//         case 'common':
//             return import(`core/translations/${language}/common+intl-icu.yml`)
//         case 'core':
//             return import(
//                 `core/bundles/${bundle}/translations/${language}/${filename || bundle}+intl-icu.yml`
//             )
//         case 'amira':
//             return import(
//                 `amira/bundles/${bundle}/translations/${language}/${filename || bundle}+intl-icu.yml`
//             )
//         case 'depot':
//             return import(
//                 `depot/bundles/${bundle}/translations/${language}/${filename || bundle}+intl-icu.yml`
//             )
//         case 'smallbox':
//             return import(
//                 `sbx/bundles/${bundle}/translations/${language}/${filename || bundle}+intl-icu.yml`
//             )
//         default:
//             throw new Error(`No location resolved for: language: ${language}, namespace: ${namespace}`)
//     }
// }


i18next
    .use(ICU)
    .use(initReactI18next)
    // .use(resourcesToBackend(getResourceImport))

export default i18next
