// Expected Time Format: XX:XX (24hr)
export const translateTime = (time, lng) => {
  if (!lng) {
    console.error('Cannot translate time, no language provided');
    return false;
  }

  const [hour, minute] = time.split(':').map(val => +val);

  switch (lng) {
    case 'fr': {
      return `${hour}h${minute}`;
    }
    default: {
      const h = (hour % 12) || 12;
      const ampm = (hour < 12 || hour === 24) ? 'am' : 'pm';
      return `${h.toString().padStart(2)} ${ampm}`;
    }
  }
}

export const commonOptions = {
  partialBundledLanguages: true,
  ns: [],
  defaultNS: 'common',
  fallbackLng: 'en',
  lng: 'en',
  resources: {},
  initImmediate: false,
  interpolation: {
    escapeValue: false, // not needed for react
    formatSeparator: ',',
    format: (value, format, lng) => {
      if (format === 'uppercase') return value.toUpperCase();
      return value;
    },
  },
};
